import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Navigation from './navigation'
import Logo from '../images/logo.svg'
import Logomark from '../images/logomark.svg'
import Container from './container'
import Gdpr from './gdpr'

const StyledHeader = styled.header`
  position: fixed;
  width: 100%;
  z-index: 100;
  background: ${props => props.theme.colors.white};
  // box-shadow: ${props => props.theme.colors.shadow};
`

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.stuck ? '.5em 0' : '1em 0'};

  a {
    border: 0;
  }
`

const StyledLogo = styled.img`
  width: ${props => props.stuck ? '5em' : '12em'};
  max-height: ${props => props.stuck ? '3em' : '6em'};
`

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stuck: false
    };
  }

  componentDidMount() {
    const newState = this

    window.onscroll = function () {
      if (window.pageYOffset > 60) {
        newState.setState({ stuck: true })
      } else {
        newState.setState({ stuck: false })
      }
    };
  }

  render() {
    return (
      <StyledHeader>
        <Gdpr />
        <StyledContainer>
          <Link to="/">
            <StyledLogo stuck={this.state.stuck} src={this.state.stuck ? Logomark : Logo} alt="Lightpaper by The Starr Conspiracy" />
          </Link>
          <Navigation />
        </StyledContainer>
      </StyledHeader>
    )
  }
}

export default Header