import { createGlobalStyle } from 'styled-components'

/** Use normalize.css instead of the polished version to keep it up to date */
import 'normalize.css';
/**
 * We can't use the theme with injectGlobal bc it's not currently aware of the
 * theme, so we'll just manually add the theme stuff here
 */
import theme from './theme';

/**
 * This is a one-time-use method to add global styles.
 *
 * Use for things like body styles and anything that can't fit as a component
 */

// export default injectGlobal`
export default createGlobalStyle`
  @import url("https://use.typekit.net/lak3zzi.css");

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  html,
  body {
    height: 100%;
    width: 100%;
  }

  @media ${props => props.theme.breakpoints.small} {
    html {
      font-size: 50%;
    }
  }

  body {
    font-family: ${props => props.theme.fonts.sofiapro};
    font-weight: 400;
    font-style: normal;
    font-size: 1.7em;
    line-height: 1.6;
    color: ${props => props.theme.colors.charcoal};
    background: ${props => props.theme.colors.white};
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  #___gatsby,
  #___gatsby > div {
    flex: 1 0 auto;
    height: 100%;
  }

  #___gatsby > div {
    display: flex;
    flex-direction: column;
  }

  main {
    flex: 1 0 auto;
  }

  section {
    padding: 4em 0;
    @media ${props => props.theme.breakpoints.minMedium} {
      padding: 8em 0;
    }
  }

  img,
  svg,
  video {
    height: auto;
    max-width: 100%;
    position: relative;
  }

  svg {
    max-height: 100%;
  }

  iframe {
    max-width: 100%;
  }

  p {
    margin: 0 0 1em 0;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: ${props => props.theme.fonts.sofiacondensed};
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 .5em;
    text-transform: uppercase;
  }

  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1em;
    line-height: 1.6;
  }

  a {
    color: ${props => props.theme.colors.blue};
    font-weight: 700;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    transition: all ${props => props.theme.animations.smoothTransitionBezier};
  }

  a:hover {
    color: ${props => props.theme.colors.blueDark};
    border-color: ${props => props.theme.colors.blueDark};
  }

  .tooltip {
    // background: ${props => props.theme.colors.white} !important;
    padding: 1em;
    max-width: 30em;
    opacity: 1 !important;
    border-radius: .3em;
    box-shadow: ${props => props.theme.shadows.deep};
  }

  @keyframes floatMedium {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(20px);
    }
    100% {
      transform: translatey(0px);
    }
  }

  @keyframes floatHigh {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(30px);
    }
    100% {
      transform: translatey(0px);
    }
  }

  @keyframes floatShort {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(10px);
    }
    100% {
      transform: translatey(0px);
    }
  }
`;
