const colors = {
  habenero: '#ff5215',
  pomegranate: 'rgb(153, 0, 32)',
  pomegranateDark: 'rgb(132, 3, 34)',
  pomegranateLight: 'rgb(188, 0, 45)',
  pomegranateDarkTrans: 'rgba(132, 3, 34, .1)',
  snozzberry: '#ca0029',
  charcoal: 'rgb(58, 62, 66)',
  charcoalDark: 'rgb(43, 46, 48)',
  officePutty: '#cbc1af',
  newsprint: '#e5e3df',
  white: '#f2f1f0',
  blue: 'rgb(0, 96, 134)',
  blueDark: 'rgb(3, 84, 109)',
}

const breakpoints = {
  small: 'screen and (max-width: 600px)',
  minSmall: 'screen and (min-width: 600px)',
  medium: 'screen and (max-width: 780px)',
  minMedium: 'screen and (min-width: 780px)'
};

const shadows = {
  deep: '0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)',
  shallow: '0 5px 10px rgba(50,50,93,.07), 0 5px 15px rgba(0,0,0,.07)',
};

const animations = {
  smoothTransitionBezier: '1000ms cubic-bezier(0.11, 0.59, 0.32, 0.9)',
  floatMedium: 'floatMedium 4s ease-in-out infinite',
  floatHigh: 'floatHigh 5s ease-in-out infinite',
  floatShort: 'floatShort 3s ease-in-out infinite',
};

const fonts = {
  sofiapro: 'sofia-pro, sans-serif',
  sofiacondensed: 'sofia-pro-condensed, sans-serif',
};

const theme = {
  colors,
  breakpoints,
  shadows,
  animations,
  fonts,
};

export default theme;