import { Link } from 'gatsby'
import styled from 'styled-components'

export default styled(Link)`
  display: inline-block;
  background: ${props => props.theme.colors.pomegranate};
  border: 0;
  box-shadow: ${props => props.theme.shadows.shallow};
  border-radius: .3em;
  font-weight: 700;
  padding: .5em 1em;
  color: ${props => props.theme.colors.white};
  text-transform: uppercase;
  font-size: .9em;
  transition: all ${props => props.theme.animations.smoothTransitionBezier};
  transform: perspective(1px) translateZ(0);
  backface-visibility: hidden;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.pomegranateLight};
    box-shadow: ${props => props.theme.shadows.deep};
    transform: scale(1.048);
  }

  &:active {
    background: ${props => props.theme.colors.pomegranateDark};
    box-shadow: none;
  }
`;