import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import get from 'lodash/get'
import { Link } from 'gatsby'

import Container from './container'
import Logo from '../images/thestarrconspiracy-logo.svg'

const Footer = styled.footer`
  padding: 2em 0;

  a {
    border: 0;
    color: ${props => props.theme.colors.charcoal};
  }

  p {
    margin-bottom: 0.5em;
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
`

const StyledLogo = styled.a`
  display: block;
  width: 10em;
  text-align: center;
  font-size: 0.8em;
`

export default ({ data }) => (
  <Footer>
    <StyledContainer>
      <div className="colophon">
        <p>
          Lightpaper
          <sup>&reg;</sup> is a registered trademark.
        </p>
        <p>
          &copy; {new Date().getFullYear()}{' '}
          <a href="https://thestarrconspiracy.com" target="_blank" rel="noopener">
            The Starr Conspiracy
          </a>
          . All rights reserved. <Link to="/privacy">Privacy Policy</Link>
        </p>
      </div>
      <StyledLogo href="https://thestarrconspiracy.com" target="_blank" rel="noopener">
        <p>Product of</p>
        <img src={Logo} alt="The Starr Conspiracy" />
      </StyledLogo>
    </StyledContainer>
  </Footer>
)
