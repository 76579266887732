import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import { ThemeProvider } from 'styled-components'
import GlobalStyle from './global-styles'
import theme from './theme'
import Header from './header'
import Footer from './footer'
import ReactTooltip from 'react-tooltip'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <>
          <Helmet>
            <html lang="en" />
          </Helmet>
          <Header />
          <ReactTooltip type="light" effect="solid" className="tooltip" />
          <GlobalStyle />
          <main>{children}</main>
          <Footer />
        </>
      </ThemeProvider>
    )}
  />
)

export default Layout
