import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Container from './container'
import CloseButton from '../images/close.svg'
import {createCookie, readCookie} from './utilities.js'

const CompanyBar = styled.div`
  display: ${props => props.showIt ? 'block' : 'none'};
  background: ${props => props.theme.colors.charcoal};
  padding: .2em;
  color: ${props => props.theme.colors.white};
  font-size: .7em;
  text-align: center;

  a {
    color: ${props => props.theme.colors.white};
    border: 0;
  }
`

const MessageContainer = styled(Container)`
  padding: 0 3em;
  position: relative;
`

const AcceptGDPR = styled.button`
  background: url(${CloseButton}) no-repeat center center;
  background-size: contain;
  border: 0;
  cursor: pointer;
  height: 1em;
  overflow: hidden;
  position: absolute;
  right: 1em;
  text-indent: -10em;
  top: 50%;
  transform: translateY(-50%);
  width: 1em;
  
`

class Gdpr extends React.Component {
  constructor(props){
    super(props)
    
    this.state = {
      showGdpr: false
    }
    
    this.acceptGdpr = this.acceptGdpr.bind(this)
  }
  
  componentDidMount() {
    const newState = this
    if (readCookie('gdpr-accepted') === 'accepted') {
      newState.setState({showGdpr: false})
      if (window.dataLayer) {
        dataLayer.push({'event': 'gdpr_accepted'})
      }
    } else {
      newState.setState({showGdpr: true})
    }
  }
  
  acceptGdpr() {
    const newState = this
    
    createCookie('gdpr-accepted', 'accepted', 365)
    newState.setState({showGdpr: false})
    if (window.dataLayer) {
      dataLayer.push({'event': 'gdpr_accepted'})
    }
  }
  
  render() {
     return (<CompanyBar showIt={this.state.showGdpr}>
      <MessageContainer>
        This website uses cookies to ensure you get the best experience on our website. <Link to='/privacy'>See Privacy Policy</Link>.
        <AcceptGDPR onClick={this.acceptGdpr}>Close</AcceptGDPR>
      </MessageContainer>
    </CompanyBar>)
  }
}

export default Gdpr