import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Button from './button'

const StyledList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  @media ${props => props.theme.breakpoints.small} {
    background: ${props => props.theme.colors.white};
    height: ${props => props.active ? '100vh' : '.1vh'};
    opacity: ${props => props.active ? 1 : 0};
    overflow: hidden;
    padding-top: 4em;
    transition: height .16s ease-in-out, opacity .16s ease-in-out;
    width: 100%;
  }

  @media ${props => props.theme.breakpoints.minSmall} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: static;

  }
`

const StyledListItem = styled.li`
  margin: 0 1em;

  @media ${props => props.theme.breakpoints.small} {
    font-size: 2.35em;
    padding-bottom: 2em;
  }
`

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.charcoal};
  text-transform: uppercase;
  font-size: .9em;

  &.active {
    border-bottom: 2px solid ${props => props.theme.colors.habenero};
    color: ${props => props.theme.colors.habenero};

    &.button {
      border: 0;
      color: ${props => props.theme.colors.white};
    }
  }
`
const MobileNavToggle = styled.button`
  display: none;
  width: 40px;
  height: 40px;

  @media ${props => props.theme.breakpoints.small} {
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    line-height: 100%;


    > span {
      font-size: ${props => props.active ? '4em' : '2em'};
      position: relative;
      top: ${props => props.active ? '-8px' : '0'};
    }
    /* NOTE: Magic numbers for font size and top. Characters have radically different X-height so font size and position must be changed. */
  }
`
class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navActive: false
    }

    this.toggleNav = this.toggleNav.bind(this)
  }

  toggleNav() {
    const newState = this

    if (this.state.navActive) {
      newState.setState({navActive: false})
    } else {
      newState.setState({navActive: true})
    }
  }

  componentDidMount() {
    const newState = this
    window.onresize = () => {
      if (window.innerWidth > 600 && this.state.navActive) {
        newState.setState({ navActive: false })
      }
    };
  }

  render() {
    return (
      <nav role="navigation">
        <StyledList active={this.state.navActive}>
          <StyledListItem>
            <StyledLink to="/features" activeClassName="active">Features</StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink to="/showcase" activeClassName="active">Showcase</StyledLink>
          </StyledListItem>
          {/* <StyledListItem>
            <StyledLink to="/pricing" activeClassName="active">Pricing</StyledLink>
          </StyledListItem> */}
          <StyledListItem>
            <Button to="/contact">Let's Talk</Button>
          </StyledListItem>
        </StyledList>
        <MobileNavToggle active={this.state.navActive} onClick={this.toggleNav}><span>{this.state.navActive ? '×' : '☰'}</span></MobileNavToggle>
      </nav>
    )
  }
}
export default Navigation
